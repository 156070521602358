@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-slate-100;
}

.datepicker-cell.disabled {
  @apply cursor-default;
  @apply opacity-50;
  @apply pointer-events-none;
}

.datepicker-cell.disabled:hover {
  @apply bg-transparent;
}

.datepicker .next-btn:disabled {
  @apply cursor-default;
  @apply opacity-50;
  @apply pointer-events-none;
}
